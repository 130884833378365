import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import CardHeader from '@fingo/lib/components/headers/CardHeader';
import CardFooter from '@fingo/lib/components/footers/CardFooter';
import KeyValueStack from '@fingo/lib/components/stacks/KeyValueStack';
import ConditionalBorderStack from '@fingo/lib/components/stacks/ConditionalBorderStack';

const DesktopCredentialsCard = ({
  id,
  title,
  subtitle,
  isOk,
  credentials,
  errorMessage,
  toggle,
}) => (
  <ConditionalBorderStack id={id} showBorder={!isOk} onConditionalClick={toggle}>
    <CardHeader status={isOk} primaryText={title} />
    <Typography px={3} mb={1}>
      {subtitle}
    </Typography>
    <CardFooter id={id} errorMessage={errorMessage} success={isOk} onActionClick={toggle}>
      <KeyValueStack items={credentials} />
    </CardFooter>
  </ConditionalBorderStack>
);

DesktopCredentialsCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  isOk: PropTypes.bool.isRequired,
  credentials: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  errorMessage: PropTypes.string.isRequired,
  toggle: PropTypes.func,
};

DesktopCredentialsCard.defaultProps = {
  credentials: [],
  subtitle: '',
  toggle: null,
};

export default DesktopCredentialsCard;
