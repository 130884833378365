import React from 'react';
import { render } from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from '@fingo/lib/theme';
import PrivateRoute from '@fingo/lib/components/router/PrivateRoute';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import client from '@fingo/lib/apollo';
import TranslationProvider from '@fingo/lib/components/translation/TranslationProvider';
import { Layout } from './components/layout';
import { App, Login } from './pages';

const Martina = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline>
      <ApolloProvider client={client}>
        <StyledEngineProvider injectFirst>
          <Router>
            <TranslationProvider>
              <Layout>
                <Switch>
                  <PrivateRoute path="/app" component={App} />
                  <Route path="/login" component={Login} />
                  <Redirect from="/" to="/login" />
                </Switch>
              </Layout>
            </TranslationProvider>
          </Router>
        </StyledEngineProvider>
      </ApolloProvider>
    </CssBaseline>
  </ThemeProvider>
);

render(<Martina />, document.getElementById('root'));
