import React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { HomeBackground } from '@fingo/lib/assets';
import LoadSrcBefore from '@fingo/lib/components/images/LoadSrcBefore';
import { useSelectedCompany } from '@fingo/lib/hooks';
import { formatRut } from '@fingo/lib/helpers';
import SelectCompanyNavItem from '@fingo/lib/components/company/SelectCompanyNavItem';
import Credentials from './Credentials';

const Home = () => {
  const selectedCompany = useSelectedCompany();

  return (
    <LoadSrcBefore src={HomeBackground}>
      <Grid
        container
        minHeight="100%"
        p={8}
        justifyContent="center"
        sx={{
          backgroundImage: `url(${HomeBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Stack
          bgcolor="fingoWhite.main"
          borderRadius={4}
          py={6}
          px={6}
          spacing={2}
          width="100%"
          direction="row"
          justifyContent="space-between"
        >
          {selectedCompany ? (
            <Stack direction="column" spacing={5}>
              <Stack direction="row" spacing={2} alignItems="flex-end">
                <Typography variant="h1" color="primary.main">
                  {selectedCompany?.name}
                </Typography>
                <Typography variant="h6">RUT: {formatRut(selectedCompany?.rut)}</Typography>
              </Stack>
              <Credentials />
            </Stack>
          ) : (
            <Typography variant="h1" color="primary.main">
              Seleccione un Empresa
            </Typography>
          )}
          <SelectCompanyNavItem addCompany={false} first={50} />
        </Stack>
      </Grid>
    </LoadSrcBefore>
  );
};

export default Home;
