import React from 'react';
import { useIsMobile } from '@fingo/lib/hooks';
import Grid from '@mui/material/Grid';
import useCompanyCredentials from '../../../hooks/useCompanyCredentials';
import MobileCredentialsCard from './MobileCredentialsCard';
import DesktopCredentialsCard from './DesktopCredentialsCard';

const Credentials = () => {
  const isMobile = useIsMobile();
  const companyCredentials = useCompanyCredentials();

  const CredentialsCardComponent = isMobile ? MobileCredentialsCard : DesktopCredentialsCard;

  return (
    <Grid container gap={2}>
      {companyCredentials.map((document) => (
        <CredentialsCardComponent key={document.title} {...document} />
      ))}
    </Grid>
  );
};

export default Credentials;
